import StandardModal from '../../shared/modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import { useCreateDocumentWizard } from '../../../contexts/CreateDocumentContext';
import { useCallback, useEffect, useState } from 'react';
import { ClientModuleTemplateDefault } from '../../../models/ClientModuleDefaults';
import ClientTemplateModuleService from '../../../services/ClientTemplateModuleService';
import { FormType } from '../../../models/FormTypes';
import { ClientFormDefault } from '../../../models/ClientFormDefaults';
import { SearchInput } from '../../shared/form-control/SearchInput';
import { InputStyle } from '../../shared/form-control/Input';
import DocumentSpaceIcon from '../../shared/icon/DocumentSpaceIcon';
import { Heading, HeadingSize } from '../../shared/text/Heading';
import Loader from '../../shared/Loader';
import LanguageUtils from '../../../utils/LanguageUtils';
import Tooltip from '../../shared/Tooltip';
import { Access } from '../../../models/Access';
import { ClientFormUserRole } from '../../../models/ClientFormUserRoles';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';
import { interpolateActionData } from '../../../utils/interpolation/ActionDataInterpolator';
import TemplateFormService from '../../../services/TemplateFormService';
import { deDuplicate } from '../../../utils/ListUtils';
import useFetchClientUsers from '../../../hooks/useFetchClientUsers';
import { useCurrentClient } from '../../../global-state/Clients';
import { useCurrentUser } from '../../../global-state/Auth';

const PickTemplateStep = () => {
  const { nextStep, prevStep, setNewDocument, classes, newDocument } = useCreateDocumentWizard();
  const { t } = useTranslation(['documents', 'organisation', 'common', 'module']);
  const [selectedClassId, setSelectedClassId] = useState<string | undefined>(newDocument.clientModuleId);
  const currentClient = useCurrentClient((x) => x.value);
  const currentUser = useCurrentUser((x) => x.value);
  const [documentTemplates, setDocumentTemplates] = useState<ClientFormDefault[]>([]);
  const [filteredDocumentTemplates, setFilteredDocumentTemplates] = useState<ClientFormDefault[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const clientUsers = useFetchClientUsers();

  const onClassClick = useCallback((classItem: ClientModuleTemplateDefault | null) => {
    setSelectedClassId(classItem ? classItem.clientModuleId : undefined);
  }, []);

  useEffect(() => {
    if (currentClient) {
      setIsLoading(true);
      const service = new ClientTemplateModuleService(currentClient?.id);
      service
        .getAllTemplateFormDefaults({ pageNumber: 1, pageSize: 999, clientModuleId: selectedClassId || undefined, types: [FormType.Document] })
        .then((res) => setDocumentTemplates(res.data))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentClient, selectedClassId]);

  useEffect(() => {
    setFilteredDocumentTemplates(
      documentTemplates.filter((x) =>
        LanguageUtils.getTranslation('title', x.templateForm.translations ?? {})
          .toLowerCase()
          .includes(searchQuery.toLowerCase()),
      ),
    );
  }, [documentTemplates, searchQuery]);

  const selectTemplate = useCallback(
    async (template: ClientFormDefault) => {
      if (template.templateForm.id && template.clientModuleId) {
        const clientModuleId = template.clientModuleId;
        const selectedClass = classes.find((x) => x.clientModuleId === clientModuleId);
        const predefinedTitle = LanguageUtils.getTranslation('predefinedTitle', template.templateForm.translations || {});
        if (predefinedTitle) {
          const templateWithPlaceholders = await TemplateFormService.getFormTemplate(template.templateForm.id, template.templateForm.version);
          template.templateForm.placeholders = templateWithPlaceholders.data.placeholders;
        }
        setNewDocument &&
          setNewDocument((prev) => {
            const templateFormId = template.templateForm.id;
            const creatorRole = template.defaults?.configuration.creatorRole;
            // order is priority (subsiquent owners will be removed if earlier owners exist)
            let defaultUsers = [
              ...(prev.accessConfiguration.users ?? []),
              ...(template.defaults?.configuration.users ?? []),
              ...(selectedClass?.defaults?.configuration.users ?? []),
            ].map((x) => {
              // Filter out users that are no longer on the client
              const users = x.users.filter((user) => !!clientUsers.data?.find((u) => u.id === user.userId));
              return { ...x, users: deDuplicate(users, 'userId') };
            });

            defaultUsers = deDuplicate(defaultUsers, 'role');

            const creatorIsOwner = creatorRole === ClientFormUserRole.Owner;
            const ownerUsers = defaultUsers.find((x) => x.role === ClientFormUserRole.Owner)?.users ?? [];
            // If the creator is owner, remove all owners from list, otherwise remove all except the first one
            if (ownerUsers.length >= (creatorIsOwner ? 1 : 2)) {
              // When the class defaults has a owner and template defaults has a owner, use template defaults and make class defaults Editor
              ownerUsers.slice(creatorIsOwner ? 0 : 1).forEach((x) => {
                const defaultUser = defaultUsers.find((u) => u.users.find((y) => y.userId === x.userId));
                if (defaultUser?.role !== ClientFormUserRole.Contributor) {
                  // Remove user from other roles
                  defaultUsers.forEach((u) => {
                    u.users = u.users.filter((y) => y.userId !== x.userId);
                  });

                  // Add user to contributor role
                  let contributorRole = defaultUsers.find((u) => u.role === ClientFormUserRole.Contributor);
                  if (!contributorRole) {
                    contributorRole = {
                      role: ClientFormUserRole.Contributor,
                      users: [],
                      tags: [],
                    };
                    defaultUsers.push(contributorRole);
                  }

                  contributorRole.users.push(x);
                }
              });
            }

            if (creatorRole && currentUser && currentUser.id) {
              const defaultUser = defaultUsers.find((def) => def.users.find((u) => u.userId === currentUser.id));
              if (defaultUser?.role !== creatorRole) {
                // remove user from other roles
                defaultUsers.forEach((u) => {
                  u.users = u.users.filter((y) => y.userId !== currentUser.id);
                });

                // add user to creator role
                let creatorRoleDto = defaultUsers.find((u) => u.role === creatorRole);
                if (!creatorRoleDto) {
                  creatorRoleDto = {
                    role: creatorRole,
                    users: [],
                    tags: [],
                  };
                  defaultUsers.push(creatorRoleDto);
                }

                creatorRoleDto.users.push({
                  userId: currentUser.id,
                  addedAsIndividual: true,
                  requiresAction: false,
                  sortOrder: null,
                  tagIds: [],
                });
              }
            }

            return {
              ...prev,
              templateFormId: templateFormId,
              clientModuleId: clientModuleId,
              number: selectedClass?.nextDocumentNumber || 1,
              predefinedTitle: predefinedTitle ? interpolateActionData(predefinedTitle, template.templateForm.placeholders || []) : undefined,
              accessConfiguration: {
                ...(selectedClass?.defaults?.configuration || {
                  accessType: Access.restricted,
                  users: [],
                  tags: [],
                }),
                users: defaultUsers,
              },
            };
          });

        nextStep && nextStep('detailStep');
      }
    },
    [classes, clientUsers.data, currentUser, nextStep, setNewDocument],
  );

  return (
    <StandardModal title={t('create-doc-wizard.title')} cancelButtonTitle={t('manage-wizard.buttons.back')} onCancelClick={prevStep}>
      <div className="-mx-3 flex-grow">
        <div className="flex">
          <div className="border-gray-6 w-1/4 border-r pr-2">
            <div className="text-dpm-16 mb-4 font-medium">{t('create-doc-wizard.templates.classes')}</div>
            <div className="h-[55vh] overflow-auto">
              <div
                {...mouseAndKeyboardCallbackProps(() => onClassClick(null))}
                className={`hover:bg-accent-light-mid cursor-pointer p-2 ${!selectedClassId ? 'bg-accent-light-mid' : ''}`}
              >
                {t('module:sections-all')}
              </div>
              {classes.map((classItem) => {
                const className = LanguageUtils.getTranslation('name', classItem.templateModule.translations || {});
                return (
                  <div
                    key={classItem.clientModuleId}
                    {...mouseAndKeyboardCallbackProps(() => onClassClick(classItem))}
                    className={`hover:bg-accent-light-mid cursor-pointer p-2 ${
                      selectedClassId === classItem.clientModuleId ? 'bg-accent-light-mid' : ''
                    }`}
                  >
                    <Tooltip text={className} truncatedTextMode>
                      {(tooltip) => (
                        <div {...tooltip} className="truncate">
                          {className}
                        </div>
                      )}
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-3/4 pl-4">
            <div className="flex justify-between gap-2">
              <div className="text-dpm-16 font-medium">{t('create-doc-wizard.templates.select')}</div>
              <div className="w-80">
                <SearchInput
                  data-cy="doc-class-search"
                  placeholder={t('common:list.filter.search')}
                  style={InputStyle.MINIMAL}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
              </div>
            </div>
            <div className="relative mt-2 h-[55vh] overflow-auto">
              {isLoading && <Loader centered size={16} />}
              {filteredDocumentTemplates.map((documentTemplate) => {
                const templateName = LanguageUtils.getTranslation('title', documentTemplate.templateForm.translations || {});
                return (
                  <div
                    key={documentTemplate.templateForm.id}
                    {...mouseAndKeyboardCallbackProps(
                      () => documentTemplate.templateForm.id && documentTemplate.clientModuleId && selectTemplate(documentTemplate),
                    )}
                    className="hover:bg-accent-light-mid border-b-gray-6 flex cursor-pointer items-start gap-4 border-b px-4 py-4"
                  >
                    <Tooltip text={templateName} truncatedTextMode>
                      {(tooltip) => (
                        <div {...tooltip} className="truncate">
                          <span className="font-medium">{templateName}</span>
                          <span className="text-accent-1 text-dpm-12 ml-2">
                            {LanguageUtils.getTranslation('name', documentTemplate.templateModuleTranslations || {})}
                          </span>
                        </div>
                      )}
                    </Tooltip>
                  </div>
                );
              })}
              {filteredDocumentTemplates.length === 0 && !isLoading && (
                <div className="flex h-full w-full justify-center text-center">
                  <div className="flex h-full w-4/5 flex-col items-center justify-center">
                    <DocumentSpaceIcon className="bg-background-1 mt-5 h-16 w-16 rounded-full p-4" />
                    <Heading size={HeadingSize.H5} className="mt-4">
                      {t('create-doc-wizard.templates.empty.title')}
                    </Heading>
                    <p className="mb-8">{t('create-doc-wizard.templates.empty.description')}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </StandardModal>
  );
};

export default PickTemplateStep;
